import React, { useContext } from 'react';
import context from '../context';
import styled from 'styled-components';

const Logo = styled.img`
  
  border-radius: 5px;
  background-color: transparent;
  max-width: 25%;
  max-height: 25%;
  object-fit: contain;
  object-position: center;
  margin-left:2rem;
  
  
`;
export default ({ dark, mobile })=> {
  const state = useContext(context);
  return(
      <Logo src={dark ? state.logoDark : mobile ? require("../images/logo-light-mobil.png") : state.logo} alt="Logo" />
  )
}